// FONTS
@font-face {
  font-family: Lausanne;
  src: url("../fonts/Lausanne-Glyph.otf") format("opentype");
}

// BREAKPOINTS
@mixin desktop {
  @media only screen and (max-width: 1439px) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (max-width: 1023px) {
    @content;
  }
}

@mixin mobile-xl {
  @media only screen and (max-width: 767px) {
    @content;
  }
}

* {
  margin: 0;
  padding: 0;
}

// STYLE
body {
  width: 100vw;
  height: 100vh;
  background-color: #202020;
  color: #ffffff;
  font-family: Lausanne;

  @include tablet {
    height: auto;
  }
}

a:link,
a:visited {
  color: #ffffff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.vector-background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;

  width: 100%;
  height: 100%;
  overflow: hidden;

  @include tablet {
    width: 100%;
    height: 600px;
  }
}

.vector {
  display: block;
  z-index: 10;
  position: absolute;
  left: 0;
  top: 40%;
  width: 850%;
  transform: translate(0, -50%);

  animation-name: slide;
  animation-duration: 70s;
  animation-timing-function: linear;
  animation-delay: 4s;
  animation-iteration-count: infinite;
  animation-fill-mode: none;

  @include desktop {
    display: none;
  }

  @include tablet {
    display: block;
  }

  @include mobile-xl {
    display: none;
  }
}

.vector-small-desktop {
  display: none;
  z-index: 10;
  position: absolute;
  left: 0;
  top: 40%;
  width: 850%;
  transform: translate(0, -50%);

  animation-name: slide;
  animation-duration: 70s;
  animation-timing-function: linear;
  animation-delay: 4s;
  animation-iteration-count: infinite;
  animation-fill-mode: none;

  @include desktop {
    display: block;
  }

  @include tablet() {
    display: none;
  }

  @include mobile-xl {
    display: none;
  }
}

.vector-small {
  display: none;
  z-index: 10;
  position: absolute;
  left: 0;
  top: 40%;
  width: 850%;
  transform: translate(0, -50%);

  animation-name: slide;
  animation-duration: 35s;
  animation-timing-function: linear;
  animation-delay: 4s;
  animation-iteration-count: infinite;
  animation-fill-mode: none;

  @include mobile-xl {
    display: block;
  }
}

@keyframes slide {
  from {
    left: 0;
  }

  to {
    left: -750%;
  }
}

.layer {
  position: absolute;
  z-index: 20;
  top: 0;
  left: 0;
  transform-origin: top left;
  width: 100%;
  height: 100%;
  background-color: #202020;
}

.content {
  position: relative;
  z-index: 20;
  width: 100%;
  height: 100%;
}

.header {
  height: 85%;

  @include tablet {
    height: 600px;
  }

  &__title {
    height: 100%;
    font-size: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include tablet {
      font-size: 5rem;
    }
  }

  &__title-studio {
    text-align: right;
    padding-right: 5rem;
    margin-top: 50px;
    position: relative;
    height: 10rem;
    overflow: hidden;
    display: block;

    @include tablet {
      padding-right: 2.188rem;
      margin-top: 30px;
    }

    span {
      display: block;
      transform: translateX(100%);
    }
  }

  &__title-arch {
    padding-left: 5rem;
    position: relative;
    height: 10rem;
    overflow: hidden;
    display: block;

    @include tablet {
      padding-left: 2.188rem;
    }

    span {
      display: block;
      transform: translateX(-100%);
    }
  }
}

.line {
  display: block;
  position: absolute;
  top: 60%;
  width: 4.688rem;
  height: 1.1rem;
  background-color: #ffffff;

  @include tablet {
    width: 1.875rem;
    height: 0.5rem;
    top: 30%;
  }

  &-studio {
    transform-origin: top right;
    right: 0;
  }
  &-arch {
    transform-origin: top left;
    left: 0;
  }
}

.footer {
  height: 15%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 80px;

  @include tablet {
    height: auto;
    flex-direction: column-reverse;
    align-items: flex-start;
    margin: 0 30px;
  }

  &__copyright {
    font-size: 0.75rem;
    margin-bottom: 50px;
    overflow: hidden;

    > span {
      display: block;
      transform: translateY(50px);
    }

    .date {
      display: inline-block;
    }
  }

  &__contact {
    display: flex;
    flex-direction: row;
    font-size: 1.375rem;
    margin-bottom: 50px;

    @include tablet {
      flex-direction: column;
      margin-bottom: 0;
      margin-top: 80px;
    }
  }

  &__list {
    margin-left: 70px;

    @include tablet {
      margin-left: 0;
      margin-bottom: 50px;
    }
  }

  .list {
    overflow: hidden;
    span {
      display: block;
      transform: translateY(50px);
    }

    a {
      display: block;
      transform: translateY(50px);
    }
  }
}
